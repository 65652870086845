<template>
  <div>
    <div class="nbanner">
      <a :href="bannerPDF" download>
        {{ bannerEntitle }}
      </a>
      <div class="swiper-container2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(v, index) in imageUrl" :key="index">
            <div class="li">
              <h3>{{ v.title }}</h3>
              <p>{{ v.subtitle }}</p>
              <img :src="v.photo" alt="" />
            </div>
          </div>
        </div>

        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="tabs">
      <ul class="f-no-s-center tabsTop">
        <li>
          <router-link class="titleColor" to="/esg"> 战略与行动 </router-link>
        </li>
        <li>
          <router-link class="titleColor" to="/report">
            报告与披露
          </router-link>
        </li>
        <li>
          <router-link class="titleColor on" to="/statement">
            声明与制度
          </router-link>
        </li>
      </ul>
    </div>
    <div class="n-statement">
      <div
        class="listbox"
        v-for="(item,index) in list"
        v-show="item.show == 1"
        :key="item.id"
      >
        <div class="li-top f-no">
          <div class="title">{{ item.title }}</div>
          <div
            v-if="item.show == 1"
            @click="change(index)"
            :class="item.isExpanded ? 'isactive botbtn flag' : 'botbtn'"
          >
            {{ item.isExpanded  ? "关闭" : "查看全部" }}
          </div>
        </div>
        <div class="itembox" v-if="item.show == 1">
          <div
            class="itemli"
            v-for="(items, index2) in displayedItems(item,index)"
            :key="index2"
            :data-id="items.id"
          >
            <div
              class="itemt"
              @click="showlib(items)"
              :data-id="items.id"
              :class="{ cur:select_index==items.id}"
            >
              <span></span>
              {{ items.title }}
            </div>
            <div class="itemb" v-show="select_index==items.id" v-if="list2">
              <div class="ul">
                <div
                  class="lis"
                  v-for="(itemss, index3) in list2"
                  :key="index3"
                >
                  <div
                    class="lit"
                    @click="showlib2(items.id, index3)"
                    :class="{ cur: select_index2 == index3 }"
                  >
                    <span></span>
                    {{ itemss.title }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="13px"
                      height="7px"
                    >
                      <image
                        x="0px"
                        y="0px"
                        width="13px"
                        height="7px"
                        xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAHCAQAAAB5eQ+RAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfoDA0PFjFHQ/RDAAAAYklEQVQI12M0ZkACjAz/ERwmJAlJhonIfGSpGIZchp0IERhDhqGNoYbhHYMLQhImlcFQyfCLIZXhKUKSiYGBgYehkCGPgYGhk2ETgwNCkolBiKGNoY/hPUMkwySGPwx3EJIAKT8V5lqCISkAAAAASUVORK5CYII="
                      />
                    </svg>
                  </div>
                  <div class="lib" v-show="select_index2 == index3">
                    <div class="libs" v-html="itemss.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cur"],
  data() {
    return {
      pageNo: 1,
      total: 0,
      list: [],
      list3: [],
      pageNo2: 1,
      total2: 0,
      list2: [],
      active: 2,
      imageUrl: "",
      bannerPDF: null,
      bannerEntitle: "",
      bannerSwiper: "",
      isactive: null,
      look: null,
      flag: false,

      select_id: null,
      select_index: null,
      select_index2: null,
      select_index3: null,
      num: 0,
      showAll: false,
    };
  },
  mounted() {
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#000");
    this.id = this.$route.query.id;
    this.selected = this.$route.query.id;
    this.getData();
    // this.getData2(this.pageNo2);
    this.getBanner();

    // this.initSwiper()
  },
  watch: {
    // cur(val){
    //    this.id = val
    //    this.selected = val
    //    this.getData()
    // }
  },
  methods: {
    getBanner() {
      this.$fetch({
        url: "index.php/esg/banner",
        type: "post",
      }).then((res) => {
        this.imageUrl = res.data.banner_list;
        this.bannerPDF = res.data.banner_pdf.files;
        this.bannerEntitle = res.data.banner_pdf.entitle;
      });
    },
    getData(id ) {
      this.$fetch({
        url: "index.php/esg/statement/",
        type: "post",
      }).then((res) => {
        this.list = res.data.list.ctype;
        this.list3 = this.list;
        this.list = this.list.map((item) => ({
          ...item,
          isExpanded: false,
          more: item.more,
            
        }));
        console.log(this.list);
        this.$nextTick(() => {
          setTimeout(function () {
            this.bannerSwiper = new Swiper(".swiper-container2", {
              autoplay: 3000,
              slidesPerView: 1,
              pagination: ".swiper-pagination",
              paginationClickable: true,
            });
          }, 500);
        });
      });
    },
    initSwiper() {
      this.bannerSwiper = new Swiper(".swiper-container2", {
        autoplay: 3000,
        slidesPerView: 1,
        pagination: ".swiper-pagination",
      });
    },
    displayedItems(item, index) {
    
      if (this.list[index].isExpanded) {
        return item.more; // 显示所有项目
      } else {
        return item.more.slice(0, 4); // 只显示前四个项目
      }
    },

    change(index) {
      this.list[index].isExpanded = !this.list[index].isExpanded;
    },
    showlib(item) {
      if (this.select_index == item.id) {
        this.select_index = null;
        this.num = 0;
      } else {
        this.num = item.id;
        this.select_index = item.id;
        this.getData2(this.select_index);
      }
    },
      showlib2(id, indexs) {
        if (this.select_index2 == indexs) {
          this.select_index2 = null;
          this.num = 0;
        } else {
          this.num = indexs;
          this.select_index2 = indexs;
        }
      },

    getData2(id) {
      this.$fetch({
        url: "index.php/esg/system/" + id,
        type: "post",
      }).then((res) => {
        this.list2 = res.data.list.data;
        this.list2= this.list2.map(item => ({
          ...item,
          isflag:false
        })) 
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.titleColor:hover {
  color: #396aff;
}
.f-no-b-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.f-no-s-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.tabsTop {
  left: 160px;
}
.f-no {
  display: flex;
  flex-flow: row wrap;
}
.nbanner {
  position: relative;
  overflow: hidden;
  img {
    //height: 560px;
    width: 100%;
  }
  a {
    z-index: 999;
    display: block;
    background-color: #396aff;
    width: 180px;
    color: #fff;
    height: 45px;
    font-size: 15px;
    line-height: 45px;
    text-align: center;
    position: absolute;
    left: 45%;
    top: 53%;
    z-index: 2;
  }
  h3 {
    position: absolute;
    left: 20%;
    font-size: 2.1875vw;
    top: 50%;
   // position: absolute;
//left: 50%;
  //  transform: translateX(-50%);
 //   font-size: 42px;
 //   top: 33%;
  }
  p {
    margin: 27px 0 60px 0;
    position: absolute;
    left: 20%;
    font-weight: normal;
    color: #c6c6c6;
    font-size: 18px;
    top: 58%;
   // margin: 27px 0 60px 0;
   // position: absolute;
  //  left: 50%;
//transform: translateX(-50%);

  //  font-weight: normal;
 //   color: #c6c6c6;
 //   font-size: 18px;
 //   top: 40%;
  }
  .swiper-pagination {
    position: absolute;
    z-index: 1;
    bottom: 20px;
  }
}
.tabs {
  background: rgb(252, 253, 253);
  font-size: 14px;
  color: #333333;
  // position: sticky;
  // top: 70px;
  z-index: 5;
  ul {
    margin: 0 auto;

    li {
      margin-right: 28px;
      a {
        font-weight: 600;
        height: 60px;
        line-height: 60px;
        display: block;
        position: relative;
        padding: 0 20px;
      }
      a.on::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(57, 106, 255);
        content: "";
      }
    }
    :nth-child(1) a {
      padding-left: 0;
    }

    .on {
      color: #396aff;
    }
  }
}

.n-statement {
  padding: 140px 0 120px;
  position: relative;
  background: #fff;
  z-index: 2;
  .listbox {
    width: 64%;
    margin: 0 auto 60px;
    .li-top {
      margin-bottom: 30px;
      justify-content: space-between;
      padding-left: 30px;
      position: relative;
    }
    .li-top::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgb(57, 106, 255);
      position: absolute;
      width: 4px;
      height: 100%;
    }
    .itembox {
      justify-content: space-between;
    }
    .li {
      background: #f5f5f5;
      width: 48%;
      margin-bottom: 4%;
      padding: 20px 35px;
      span {
        transition: all 0.6s;
      }
      .icon {
        width: 16px;
        height: 16px;
        fill: #333;
        transition: all 0.3s;
      }
    }
    .li:hover {
      span {
        color: #396aff;
      }
      .icon {
        fill: #396aff;
      }
    }
    .itemli {
      position: relative;
      margin-bottom: 15px;

      .itemt {
        width: 100%;
        border-radius: 4px;
        border: 1px solid rgba(102, 102, 102, 0.2);
        padding-top: 25px;
        padding-bottom: 25px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        padding-left: 31px;
        padding-right: 31px;
        font-size: 18px;
        transition: all 0.3s;
        cursor: pointer;

        span {
          display: inline-flex;
          border-radius: 50%;
          background-color: rgb(57, 106, 255);
          width: 8px;
          height: 8px;
          margin-right: 11px;
        }
      }
      .itemt:hover {
        border-color: rgb(245, 245, 245);
        background-color: rgb(245, 245, 245);
      }
      .itemt.cur {
        border-color: rgb(245, 245, 245);
        background-color: rgb(245, 245, 245);
      }
      .itemb {
        margin-top: 4px;
        border: 1px solid rgba(102, 102, 102, 0.2);
        border-radius: 4px;
        .ul {
          padding: 0 50px 18px;

          .lis {
            border-bottom: 1px solid rgba(51, 51, 51, 0.1);
            padding-bottom: 20px;
            .lit {
              margin-top: 32px;
              display: flex;
              align-items: center;
              cursor: pointer;
              font-size: 18px;
              position: relative;
              span {
                display: inline-flex;
                background-color: rgb(57, 106, 255);
                width: 6px;
                margin-right: 10px;
                height: 2px;
              }
              svg {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(-180deg);
                image {
                  filter: contrast(0);
                }
              }
            }
            .libs{
              font-size: 18px;

            }
            .cur svg {
              transform: rotate(0);
              image {
                filter: none;
              }
            }

            .lib {
              margin-top: 16px;
              font-size: 14px;
            }
          }
          :last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  .title {
    font-size: 30px;
    line-height: 1.2;
    color: #222;
  }
  .botbtn {
    width: 120px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    border: 1px solid #396aff;
    color: #396aff;
    text-align: center;
    cursor: pointer;
    transition: all 0.6s;
  }
  .botbtn.isactive {
    background-color: #396aff;
    color: #fff;
  }
}
@media (min-width: 1280px) {
  .tabs {
    background: rgb(252, 253, 253);
    font-size: 14px;
    color: #333333;
    z-index: 5;
    ul {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      li {
        margin-right: 60px;
      }
      .on {
        color: #396aff;
      }
    }
  }
}

::v-deep .libs a {
  transition: all 0.3s;
}
::v-deep .libs a:hover {
  color: #396aff !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}
</style>